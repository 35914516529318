<template>
  <CompositionList />
</template>

<script>
  export default {
    components: {
      CompositionList: () => import('@/components/stock/composition/CompositionList')
    }
  }
</script>